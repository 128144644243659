<template>
  <b-sidebar
    id="details"
    :visible="isDetailsSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-details-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Organization Details
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
        >

          <!-- Logo -->
            <b-form-group
            >
              <h5><strong>Logo:</strong> </h5>
              <b-avatar
              ref="previewEl"
              :src="detailsData.logo"
              size="90px"
              rounded
            />
            </b-form-group>

          <!-- Website -->
          <p><strong>Website:</strong> {{detailsData.domain}}</p>

          <!-- Address -->
            <p><strong>Address:</strong> {{detailsData.contact_address}}</p>

          <!-- Address -->
          <p><strong>Description:</strong> {{detailsData.description}}</p>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {convertToSlug} from '@core/utils/utils.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAvatar,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isDetailsSidebarActive',
    event: 'update:is-details-sidebar-active',
  },
  props: {
    isDetailsSidebarActive: {
      type: Boolean,
      required: true,
    },
    detailsData: {
      required: true
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
