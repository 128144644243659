<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Add New Organization
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Package Plan -->
          <validation-provider
            #default="validationContext"
            name="Package Plan"
            rules="required"
          >
            <b-form-group
              label="Select a Package"
              label-for="package-plan"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="form.package_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="packageOptions"
                :reduce="val => val.id"
                label="name"
                :clearable="true"
                input-id="package-plan"
                @input="setMonth"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- month -->
          <validation-provider
            #default="validationContext"
            name="month"
            rules="required"
          >
            <b-form-group
              label="For"
              label-for="month"
            >
              <b-input-group >
                <b-form-input
                  id="month"
                  v-model.number="form.month"
                  :state="getValidationState(validationContext)"
                  trim
                  type="number"
                  :min="min_month"
                  step="1"
                />

                <b-input-group-append  is-text>
                    month(s)
                </b-input-group-append>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>

              </b-input-group >
            </b-form-group>
          </validation-provider>

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="form.name"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Organization Name"
                @keyup="setSlug()"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            rules="required|email"
          >
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="form.contact_email"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Contact Email"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Phone -->
          <validation-provider
            #default="validationContext"
            name="Phone"
          >
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <cleave
                id="phone"
                v-model="form.contact_phone"
                class="form-control"
                :raw="false"
                :options="clevePhone"
                placeholder="Contact Phone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>

            </b-form-group>
          </validation-provider>

          <!-- Address -->
          <validation-provider
            #default="validationContext"
            name="Address"
          >
            <b-form-group
              label="Address"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="form.contact_address"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Contact Address"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Domain -->
          <validation-provider
            #default="validationContext"
            name="Domain"
          >
            <b-form-group
              label="Website"
              label-for="domain"
            >
              <b-form-input
                id="domain"
                v-model="form.domain"
                :state="getValidationState(validationContext)"
                trim
                placeholder="start with http"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Add
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BInputGroupAppend, BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import {convertToSlug} from '@core/utils/utils.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.bd'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    vSelect,
    Cleave,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    packageOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      clevePhone: {
        phone: true,
        phoneRegionCode: 'US',
      },
    }
  },
  methods: {
    setSlug() {
        this.form.slug = convertToSlug(this.form.name);
    },
    setMonth() {
        var package_id = this.form.package_id;
        this.min_month = null;
        if(package_id){
          const dataIndex = this.packageOptions.findIndex((u) => u.id === package_id)
          var plan = this.packageOptions[dataIndex]

          if(plan){
            if(plan.duration == "Year"){
              this.min_month = 12;
            }
            else if(plan.duration == "Month"){
              this.min_month = 1;
            }
            else{
              this.min_month = 1;
            }
          }
        }
        this.form.month = this.min_month;
    },
    onSubmit() {
      store.dispatch('app-org/addData', this.form)
        .then((response) => {
          if(response.data.status == 1 || response.data.success){
            this.$emit('refetch-data')
            this.$emit('update:is-add-new-sidebar-active', false)
            this.$swal({
                  icon: 'success',
                  title: response.data.message ? response.data.message : 'successfully added!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                  timeout: 2000
                })
          }
          else if(response.data.status == 0){
            var errors = response.data.errors;
              this.$swal({
                    icon: 'error',
                    title: errors.join(', ' ),
                    customClass: {
                      confirmButton: 'btn btn-danger',
                    },
                  })
            }
      })
    }
  },
  setup(props, { emit }) {
    var min_month;
    var blankData = {
      name: '',
      contact_email: '',
      contact_phone: '',
      contact_address: '',
      domain: '',
      slug: '',
      package_id: '',
    }

    var form = blankData
    const resetData = () => {
      form = blankData //JSON.parse(JSON.stringify(blankData))
    }

    

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      form,
      min_month,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.input-group-append .input-group-text {
    border-right: 1px solid #d8d6de !important;
}
</style>
