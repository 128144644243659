import axios from '@axios'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken')

import {checkResponseStatus} from '../checkResponseStatus'


export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDatas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
       axios
          .get('/api/v1/organizations', { params: queryParams })
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
              
          }).catch(error => reject(error))
      })
    },
    addData(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/organizations', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchUsersData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/organization/${queryParams.id}/users`, { params: queryParams })
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchData(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/organizations/${id}`)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    editData(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/organizations/${requestData.id}`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeArchiveStatus(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/organization/${requestData.id}/change-archive-status`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    changeApproveStatus(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/organization/${requestData.id}/change-approve-status`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateOrgPackage(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/organization/${requestData.id}/package-update`, requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchPackageOption(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/option/packages')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    fetchOrgProfile(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/my-organization')
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    updateOrgProfile(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/my-organization/update', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    packageList(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/public/package/list')
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    renewOrgPackage(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/my-organization/renew-package', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getUpgradePackageCost(ctx, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/v1/my-organization/get-upgrade-package-cost', requestData)
          .then((response) =>  {
              checkResponseStatus(response.data)
              resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
